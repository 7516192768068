<script setup lang="ts">

import { defineAsyncComponent } from 'vue'
import { MapData } from '@/Interfaces/Map'
import { ref, inject } from 'vue'
import MapOverlay from '@/Components/MapOverlay.vue'

const TheMap = defineAsyncComponent(() => import('@/Components/map/TheMap.vue'))

defineProps<{
    city: string
    items: MapData
}>()

const markersView = {
    mapMarker: {
        mainIcon: {
            icon: '/images/mapMarker-red.png',
            iconHighlighted: '/images/mapMarker-red-highlighted.png',
            width: 23.5,
            height: 32
        }
    }
}

const showMap = ref(false)

const mapTitle = inject('mapTitle')

</script>

<template>
    <template v-if="items">
        <h2 id="mapHostels" class="my-4">
            Map for {{ mapTitle }}
        </h2>
        <p>
            We've got your back when it comes to finding a great hostel location in {{ city }}. We've gone
            ahead and mapped out all the hostels for you so you can get a good idea of where they're all at. This map
            will
            make your life so much easier!
        </p>
        <div id="location" class="card text-dark overflow-hidden border-0 rounded-0 mb-3">
            <div class="map-wrapper map-wrapper-300">
                <component
                    :is="TheMap"
                    v-if="showMap"
                    :map-data="items"
                    :marker-data="markersView"
                    :height="300"
                />

                <MapOverlay v-else v-model="showMap" :alt="`Map for ${mapTitle}`" />
            </div>
        </div>
    </template>
</template>

<style scoped lang="scss">

</style>